.papers {
	padding: 15px;
	background-color: #fff;
	border: 1px solid #ccc;
	-webkit-box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1), 3px 3px 0 #fff,
		4px 4px 0 rgba(0, 0, 0, 0.125), 6px 6px 0 #fff, 7px 7px 0 rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1), 3px 3px 0 #fff,
		4px 4px 0 rgba(0, 0, 0, 0.125), 6px 6px 0 #fff, 7px 7px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1), 3px 3px 0 #fff,
		4px 4px 0 rgba(0, 0, 0, 0.125), 6px 6px 0 #fff, 7px 7px 0 rgba(0, 0, 0, 0.15);
}
.files input {
	outline: 2px dashed #92b0b3;
	outline-offset: -10px;
	-webkit-transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
	transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
	padding: 25% 0 65px 10%;
	text-align: center !important;
	margin: 0;
	width: 100% !important;
}
.files input:focus {
	outline: 2px dashed #92b0b3;
	outline-offset: -10px;
	-webkit-transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
	transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
	border: 1px solid #92b0b3;
}
.files {
	position: relative;
}
.files:after {
	pointer-events: none;
	position: absolute;
	top: 15%;
	left: 0;
	right: 0;
	width: 24px;
	height: 24px;
	content: '';
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAV1BMVEVHcEwREhIREhISEhIRExMREhIQERESExMPDw8REhISExMREhIRExMREhIREhISExMREhIQExMSEhIREhIQEREQEREREREQEhIRFBQREREAAAASExMSExNxTVCkAAAAHHRSTlMAcopSTfn1/BDq7lhja7hBflw2eOXyRjsyKwXSNKH1VgAAAO9JREFUWMPt1tsSgiAQBuA1VULxTGa17/+cDWBSJQhxV+yFF8L/DSPuAIC1+pJmEFDXApGHAEdEJBGIQAQiEIEI/DcwNM3FBLyMmYoj0nkbaAvE2+6BThCxnLcAkUe6u4IDCmH4BCaRxwTchNP5HZhy8b5z+YxPggYymR/dNkILK6DyietWSqFoNZD65QG6RVgAla99ficlTAqoiHceYBSZvBKAyjPfNpACWR/Mv5ES1EXYN62oBdJbpqXUeBuuH/nKlqWWBqnteZUVU4zLY2IrUuOwzFoB6Dm3XPcdAHtFIAK/BoRUOEDD8hSyIIFmd/PlSaHHvoAaAAAAAElFTkSuQmCC);
	display: block;
	margin: 0 auto;
	background-size: 100%;
	background-repeat: no-repeat;
}
.color input {
	background-color: #f1f1f1;
}
.files:before {
	position: absolute;
	bottom: 0;
	left: 0;
	pointer-events: none;
	width: 100%;
	right: 0;
	height: 29%;
	content: ' or drag it here. ';
	display: block;
	margin: 0 auto;
	color: #2ea591;
	font-weight: 600;
	text-transform: capitalize;
	text-align: center;
}
.preview-image {
	width: 100%;
	max-width: 100px;
	max-height: 100px;
	overflow: hidden;
	z-index: 1;
}
.preview-image-remove {
	z-index: 1;
	position: absolute;
	left: 15px;
	top: 0;
	padding: 5px;
	line-height: 0.8;
}

.footer-spacing {
	padding-bottom: 100px;
}
